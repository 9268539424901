import { graphql } from 'gatsby';
import React from 'react';
import tw, { css, theme } from 'twin.macro';
import Sandbox from '../atoms/sandbox';
import Curve from '../svgs/curve';
import QuoteIcon from '../../images/icons/quote.inline.svg';
import Breadcrumb from '../atoms/breadcrumb';

const Quote = ({ data }) => (
  <div tw="bg-brand-purple-100 relative">
    <Curve
      tw="absolute w-full bottom-full left-0 right-0"
      fill={theme`colors.brand.purple.100`}
    />
    <div
      css={[
        tw`max-w-screen-lg pt-6 px-6 pb-12 mx-auto`,
        tw`sm:(pt-12 pb-20 px-20)`,
        tw`lg:(px-28 pt-20 pb-28)`,
        tw`xl:(px-36 pb-36)`,
        tw`2xl:pb-40`,
      ]}
    >
      {data.breadcrumb && (
        <Breadcrumb
          text={data.breadcrumb}
          theme="light"
          tw="mb-8 text-center sm:text-left"
        />
      )}
      <QuoteIcon tw="w-20 mx-auto mb-6 text-brand-purple-600 text-opacity-25 xl:(w-24 mb-8)" />
      <Sandbox
        html={data.body}
        tw="font-accent text-2xl text-brand-purple-600 text-center leading-tight xl:text-4xl"
      />
      {data.author && (
        <p tw="uppercase text-center text-sm flex flex-wrap items-center mt-2 justify-center lg:mt-6 xl:mt-8">
          <b
            css={[
              css`
                ${tw`flex items-center`}
                &:before {
                  content: '';
                  ${tw`w-4 -ml-4 mr-4 border-t border-brand-purple-800 block`}
                }
              `,
            ]}
          >
            {data.author}
            {data.author_info && ' ,'}
          </b>
          {data.author_info && <span tw="ml-2">{data.author_info}</span>}
        </p>
      )}
    </div>
  </div>
);

export const query = graphql`
  fragment Quote on STRAPI_ComponentContainersQuote {
    id
    author
    body
    author_info
    breadcrumb
  }
`;

export default Quote;
