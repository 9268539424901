import React from 'react';
import { motion } from 'framer-motion';
import InView from 'react-intersection-observer';

const DEFAULT_PATHS = [
  'M 0 39 C 200 13.1825358 400 .27380371 600 .27380371S1000 13.1825358 1200 39',
  'M 0 39 C 307 61 435 49 594 47 S 924 61 1200 39',
];
const DEFAULT_VIEWBOX = '0 0 1200 39';

const SVGCurve = ({ fill = '#000', className, paths, viewBox }) => {
  const variants = {
    in: {
      d: paths ? paths[0] : DEFAULT_PATHS[0],
    },
    out: {
      d: paths ? paths[1] : DEFAULT_PATHS[1],
    },
  };
  return (
    <InView>
      {({ ref, inView }) => (
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={viewBox || DEFAULT_VIEWBOX}
          ref={ref}
          initial="out"
          className={className}
          animate={inView ? 'in' : 'out'}
        >
          <motion.path
            fill={fill}
            variants={variants}
            transition={{ type: 'spring', bounce: 1, damping: 5 }}
          />
        </motion.svg>
      )}
    </InView>
  );
};

export default SVGCurve;
