import { motion } from 'framer-motion';
import React from 'react';
import InView from 'react-intersection-observer';
import tw, { css } from 'twin.macro';

const Breadcrumb = ({ text, theme, className }) => (
  <InView threshold={0.5}>
    {({ ref, inView }) => (
      <motion.p
        ref={ref}
        variants={{
          out: {
            opacity: 0,
          },
          in: {
            opacity: 1,
          },
        }}
        initial="out"
        animate={inView ? 'in' : 'out'}
        css={[
          tw`
    text-xs uppercase mb-4 2xl:(text-sm)
  `,
          css`
            &:after {
              content: '';
              ${tw`w-8 border-t border-brand-green my-1 block mx-auto`}
            }
            @media (min-width: 640px) {
              ${tw`absolute right-full top-20 mb-0 -mr-6 origin-top-right whitespace-nowrap`}
              transform: rotate(-90deg);
              &:after {
                ${tw`my-0 absolute left-0 -ml-16 w-12 top-2`}
              }
            }
            @media (min-width: 1024px) {
              ${tw`-mr-10 top-32`}
            }
            @media (min-width: 1280px) {
              ${tw`-mr-20`}
            }
          `,
          theme === 'dark' ? tw`text-white` : tw`text-brand-purple-800`,
        ]}
        className={className}
      >
        {text}
      </motion.p>
    )}
  </InView>
);

export default Breadcrumb;
